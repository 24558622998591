import React from 'react';
import { useTranslation } from './TranslationContext';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    const language = event.target.value; 
    console.log("Changing language to:", language); 
    if (i18n) {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', language); // حفظ اللغة في localStorage

      // تغيير اتجاه النص في body
      document.body.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');
    }
  };
  
  return (
    <div className="language-selector mt-2">
      <select onChange={changeLanguage}>
        <option value="en">English</option>
        <option value="ar">العربية</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
