import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { TranslationProvider } from './TranslationContext';
import i18n from './i18n';

// قراءة اللغة من localStorage وتعيين الاتجاه
const storedLanguage = localStorage.getItem('i18nextLng') || 'en';
document.body.setAttribute('dir', storedLanguage === 'ar' ? 'rtl' : 'ltr');

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TranslationProvider>
      <QueryClientProvider client={queryClient}>
        <App />
        <ToastContainer />
      </QueryClientProvider>
    </TranslationProvider>
  </React.StrictMode>
);

reportWebVitals();
