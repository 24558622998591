import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import ScrollToTop from '../Home/ScrollToTop';
import Partners from '../Home/Partners';
import Footer from '../Footer/Footer';

export default function Layout() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Navbar />
      <div className="mt-100">

      <Outlet />
      <ScrollToTop/>
      <Footer/>
      </div>
      {showButton && (
        <button onClick={scrollToTop} className="scroll-to-top">
          <i className="fa fa-arrow-up"></i>
        </button>
      )}
    </>
  );
}
