import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export default function LoginNaw() {
  const { t } = useTranslation();
  const fetchServiceData = async () => {
    const { data } = await axios.post('https://shipments.ahlanuof.com/api/home', {
      company_id: '262',
    });
    return data;
  };

  const { data, error, isLoading } = useQuery(['service'], fetchServiceData);



  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="mt-5 container">
        <div className="row">
          <div className="col-lg-4 col-md-12 Serviceimg">
            <img src={data?.data?.service[0]?.image} className="w-100" alt="Service" />
          </div>  
          <div className="col-lg-8 col-md-12 mt-5">
            <h1 className="color-text">{data?.data?.service[0]?.title}</h1>
            <p className="mt-5">
          {data?.data?.service[0]?.content}
            </p>
            <button className="btn btn-slider btn-color mb-5 mt-3 fw-bold px-4 btn-center d-flex h-40 bg-color">
              <p className="text-white">      {t("registernow")}   </p>
              <p className="text-white px-3">
                <i className="fa-solid fa-arrow-left"></i>
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
