import React, { useEffect, useState } from 'react'
import Slider from '../Navbar/Slider/Slider'
import Delivery from '../Navbar/Delivery/Delivery'
import Navbar from '../Navbar/Navbar'
import Express from './Express/Express'
import LoginNaw from './LoginNaw'
import CounterSection from './CounterSection'
import Steps from './Steps'
import Partners from './Partners'
import ScrollToTop from './ScrollToTop'
import { Helmet } from 'react-helmet'
import video from "../../../src/Withyou Gif.mp4"; // تأكد من أن مسار الفيديو صحيح

export default function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setLoading(false);
    }, 3500); // Adjust the delay as necessary
  }, []);
  return (
    <div>

{loading && (
        <div className="loading-overlay">
          {/* <div className="spinner"></div> */}
          <div className="fixed-logo">
            <a className="" href="#">
              <video className="" autoPlay loop muted>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </a>
          </div>
        </div>
      )}


      <Helmet>
        <title>  الصفحة الرئسيه  </title>
      </Helmet>
      {/* <Navbar /> */}
      <Slider/>
      <Delivery/>
      <Express/>
      <LoginNaw/>
      {/* <CounterSection/> */}
      <Steps/>
      {/* <ScrollToTop/> */}
      {/* <Partners/> */}
    </div>
  )
}
