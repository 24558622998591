import axios from 'axios';
import React from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';

export default function About() {
  const fetchAbout = async () => {
    const { data } = await axios.post('https://shipments.ahlanuof.com/api/about-us', {
      company_id: "262"
    });
    return data;
  };

  const fetchHome = async () => {
    const { data } = await axios.post('https://shipments.ahlanuof.com/api/home', {
      company_id: "262"
    });
    return data;
  };

  const { data: aboutData, error: aboutError, isLoading: aboutLoading } = useQuery(['about'], fetchAbout);
  const { data: homeData, error: homeError, isLoading: homeLoading } = useQuery(['home'], fetchHome);

  if (aboutLoading || homeLoading) return <div>
    <div className='d-center mt-5 pt-5'>
    <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#f9bf3c"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
    </div>
  </div>;
  if (aboutError) return <div>Error: {aboutError.message}</div>;
  if (homeError) return <div>Error: {homeError.message}</div>;

  const aboutSliderData = aboutData?.data || [];
  const homeSliderData = homeData?.data || [];

  // console.log("About Data:", aboutSliderData);
  // console.log("Home Data:", homeSliderData?.staticImg[0]?.image);

  return (
    <div className="">
      <div className="image-container">
        <img 
          src={homeSliderData?.staticImg[0]?.image}
          alt="About" 
          className="background-image w-100"
        />
        <div className="text-overlay">
          من نحن  
        </div>
      </div>
      <div className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 mt-3">
              <h2 className='color-text'>{aboutSliderData?.aboutHeader[0]?.title}</h2>
              <div className="mt-3">
                <p className='mt-4'>{aboutSliderData[0]?.content}</p>
                <p className='mt-4'>{aboutSliderData?.aboutContent[0]?.content}</p>
                <p className='mt-4'>{aboutSliderData?.aboutContent[1]?.content}</p>
              </div>
              <div className="mt-5">
                <h2 className='color-text'>Home Data</h2>
                <p className='mt-4'>{homeSliderData[0]?.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
              <img src={aboutSliderData?.aboutHeader[0]?.image} className='w-100' alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
