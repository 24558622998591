import React, { useState } from 'react';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BallTriangle, FallingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

export default function ForgetPassword() {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('https://shipments.ahlanuof.com/api/clients/forget-password/send-code', formData);
      // console.log('Login successful:', response.data);
      if (response.data.status === true) {
        toast.success(response?.data.message);
        // localStorage.setItem('token', response.data?.data.token);
        navigate('/Checkcode');
      } else {
        toast.error(response?.data.message);
      }
      // Handle successful login response here (e.g., save token, redirect, etc.)
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Error logging in, please try again.');
      // Handle login error here (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt- pt-1">
      <div className="text-center col-5 mx-auto login-container mt-3">
        <img
          src="https://webwith.sahla-eg.com/static/media/logo.862c0c531ee504ef9d75.png"
          className="pt-3 img-100 login-img"
          alt="Logo"
        />
        <form onSubmit={handleSubmit}>
          <div className="mt-5 px-3">
            <label htmlFor="email" className="fw-bold text-dark text-start d-flex">
              البريد الاكتروني 
            </label>
            <div className="mb-3 mt-2">
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="  "
              />
            </div>
          </div>
        
          <button type="submit" className="btn bg-color text-white w-75 mt-3 mx-auto mb-3" disabled={isLoading}>
            {isLoading ? (
              <span>
                <i className="fas fa-spinner fa-spin"></i> جاري التحميل...
              </span>
            ) : (
              ' ارسال الكود'
            )}
          </button>
        </form>

      </div>
    </div>
  );
}
