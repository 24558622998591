import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Delivery() {
    const { t } = useTranslation();
  return (
    <div>
      
      <div className="mt-5">
        <div className="color-text text-center">
            <h2 className='text-color'>  {t("fastshipping")}   </h2>
        </div>

    <div className="container">
        <div className="row mt-5">
            <div className="col-lg-4 col-md-6 col-sm-12  mt-5">
                <div className="text-center w-75 mx-auto  cart-hover mt-5">
                    <h3 className='pt-3 p-hover '>  {t("shipmentaccounts")}     </h3>
                    <div className="mt-3 img-cart">
                        <img src="https://tawseela-express.com/content/assets/images/calculator.png" className='w-100' alt="" />
                    </div>
                    <p className='text-end mx-5 mt-3 p-hover'>  lorem loremloremlorem lorem </p>
                    <div className="row mx-2">
                        <div className="col-6 mt-3">
                        <select class="form-select" aria-label="Default select example">
  <option selected>شركة شحن</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
                        </div>
                        <div className="col-6 mt-3">
                            <input type="text" className='form-control' placeholder='وزن الشحنه' name="" id="" />
                        </div>
                        <div className="col-12 mt-3">
                            <input type="text" className='form-control bg-input' placeholder='سعر الشحنه' name="" id="" />
                        </div>
                        <div className="d-center mt-2">

                        <button className='btn   mt-2 fw-bold   create-shna mb-4'>    {t("createhipment")}    </button>
                        </div>
                    </div>


                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 h-cart mt-5">
                <div className="text-center w-75 mx-auto  cart-hover">
                    <h3 className='pt-3 p-hover'>        {t("shipmenttracking")}    </h3>
                    <div className="mt-5 img-cart">
                        <img src="https://tawseela-express.com/content/assets/images/track.png" className='w-100' alt="" />
                    </div>
                    <div className="row mx-2">
                 
                        <div className="col-12 mt-5">
                            <input type="text" className='form-control bg-input' placeholder='ادخل رقم الطلب ' name="" id="" />
                        </div>
                        <div className="d-center mt-5">

                        <button className='btn   mt-2 fw-bold   create-shna mb-4'>      {t("tracking")} </button>
                        </div>
                    </div>


                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 h-cart mt-5">
                <div className="text-center w-75 mx-auto  cart-hover">
                    <h3 className='pt-3 p-hover'>      {t("ongoingsupport")}   </h3>
                    <div className="mt-5 img-cart">
                        <img src="https://tawseela-express.com/content/assets/images/customer.jpg" className='w-100' alt="" />
                    </div>
                    <div className="row mx-2">
                 
                        <div className="col-12 mt-5">
                            <p className='p-hover '>
                            نسعد باتصالاتكم واستفساراكم على مدار الساعة


                            </p>
                        </div>
                        <div className="d-center mt-5 pb-5">
                        <button className='btn   mt-2 fw-bold   create-shna mb-5'>      {t("communication")} </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>


      </div>


    </div>
  )
}
