import React, { useState } from 'react';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BallTriangle, FallingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

export default function Checkcode() {
  const [formData, setFormData] = useState({
    email: '',
    code: '',
    password: '',
    password_confirmation: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.patch('https://shipments.ahlanuof.com/api/clients/forget-password/reset-password', formData);
      if (response.data.status === true) {
        toast.success(response?.data.message);
        // يمكن إضافة إعادة التوجيه هنا بعد التسجيل الناجح
        navigate('/login');
      } else {
        toast.error(response?.data.message);
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Error logging in, please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt- pt-1">
      <div className="text-center col-5 mx-auto login-container mt-3">
        <img
          src="https://webwith.sahla-eg.com/static/media/logo.862c0c531ee504ef9d75.png"
          className="pt-3 img-100 login-img"
          alt="Logo"
        />
        <form onSubmit={handleSubmit}>
          <div className="mt-2 px-3">
            <label htmlFor="email" className="fw-bold text-dark text-start d-flex">
              البريد الإلكتروني
            </label>
            <div className="mb-3 mt-2">
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="البريد الإلكتروني"
              />
            </div>
          </div>
          <div className="mt-2 px-3">
            <label htmlFor="code" className="fw-bold text-dark text-start d-flex">
              الكود
            </label>
            <div className="mb-3 mt-2">
              <input
                type="text"
                className="form-control"
                id="code"
                name="code"
                value={formData.code}
                onChange={handleChange}
                placeholder="الكود"
              />
            </div>
          </div>
          <div className="mt-2 px-3">
            <label htmlFor="password" className="fw-bold text-dark text-start d-flex">
              كلمة المرور
            </label>
            <div className="mb-3 mt-2">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="كلمة المرور"
              />
      
            </div>
          </div>
          <div className="mt-2 px-3">
            <label htmlFor="password_confirmation" className="fw-bold text-dark text-start d-flex">
              تأكيد كلمة المرور
            </label>
            <div className="mb-3 mt-2">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password_confirmation"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                placeholder="تأكيد كلمة المرور"
              />
            </div>
          </div>
          <button type="submit" className="btn bg-color text-white w-75 mt-3 mx-auto mb-3" disabled={isLoading}>
            {isLoading ? (
              <span>
                <i className="fas fa-spinner fa-spin"></i> جاري التحميل...
              </span>
            ) : (
              'إرسال'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
