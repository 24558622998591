import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// import { useQuery } from '@tanstack/react-query';

export default function Slider() {

  const { t } = useTranslation();

  const fetchAptData = async () => {
    const { data } = await axios.post('https://shipments.ahlanuof.com/api/home', {
      company_id: "262"
    });
    return data;
  };

  const { data, error, isLoading } = useQuery(['aptData'], fetchAptData);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const sliderData = data?.data?.slider || [];

  return (
    <div>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {sliderData.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={index === 0 ? 'active' : ''}
              aria-current={index === 0 ? 'true' : undefined}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {sliderData.map((item, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''} vhh-100`}>
              <div className='position-relative'>
                <img src={item.image} className="d-block w-100" alt={item.altText} />
                <div className='position-absolute top-0 start-0 w-100 h-100 bg-dark' style={{ opacity: 0.6 }}></div>
              </div>
              <div className="carousel-caption flex-column d-center mb-50">
                <h1 className='fw-bold responsev-header'>{item.title}</h1>
                <h5 className='fw-bold mt-4'>{item.content}</h5>
                <button className='btn btn-slider mb-5 mt-2 fw-bold px-4 btn-center'>
                
              {t("shipmentnow")}
                </button>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}
