import React, { createContext, useContext } from 'react';
import i18n from './i18n'; // تأكد من أن i18n يتم استيراده بشكل صحيح

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  return (
    <TranslationContext.Provider value={{ i18n }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  return useContext(TranslationContext);
};
