import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Contactinfo from "./Contact_info";
import { useQuery } from "react-query";
import { BallTriangle } from "react-loader-spinner";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: ""
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https:shipments.ahlanuof.com/api/contact-us', formData);
      console.log("Response:", response.data);
      setLoading(false);
      toast.success("تم إرسال الرسالة بنجاح!");
      // Reset form after successful submission
      setFormData({
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: ""
      });
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      toast.error("حدث خطأ أثناء إرسال الرسالة. يرجى المحاولة مرة أخرى.");
    }
  };

  const fetchHome = async () => {
    const { data } = await axios.post('https://shipments.ahlanuof.com/api/home', {
      company_id: "262"
    });
    return data;
  };

  const { data: homeData, error: homeError, isLoading: homeLoading } = useQuery(['home'], fetchHome);

  const homeSliderData = homeData?.data || [];
  const backgroundImage = homeSliderData.staticImg && homeSliderData.staticImg.length > 0 ? homeSliderData.staticImg[0].image : null;

  if (homeLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>

     <div className='d-center'>
    <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#f9bf3c"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
    </div>
      </div>
    );
  }

  return (
    <div>
      <div className="image-container">
        {backgroundImage && (
          <img
            src={backgroundImage}
            alt="About"
            className="background-image"
          />
        )}
        <div className="text-overlay">إتصل بنا</div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h3 className="color-text">نسعد باتصالاتكم واستفساراكم على مدار الساعة</h3>
            <p className="fw-bold">لديك إستفسار؟ أرسل لنا إستفسارك بإستخدام النموذج أدناه وسيتواصل معك فريق توصيلة اكسبرس</p>

            <div className="mt-4">
              <form onSubmit={handleSubmit}>
                <div className="border">
                  <div className="row mx-3 mt-4 mb-4">
                    <div className="col-6 mt-3">
                      <label htmlFor="name">الاسم</label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="phone">رقم الجوال</label>
                      <input
                        type="number"
                        className="form-control mt-2"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="email">البريد الالكتروني</label>
                      <input
                        type="email"
                        className="form-control mt-2"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="subject">موضوع الرسالة</label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <label htmlFor="message">الرسالة</label>
                      <textarea
                        name="message"
                        className="form-control mt-3"
                        id="message"
                        cols="30"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>

                    <div className="mt-4">
                      <button type="submit" className="btn bg-color text-white" disabled={loading}>
                        {loading ? (
                          <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="mx-2">جارٍ الإرسال...</span>
                          </>
                        ) : (
                          <>
                            <span className="mx-2">
                              <i className="fa fa-paper-plane" aria-hidden="true"></i>
                            </span>
                            <span>ارسال</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

          </div>

          <div className="col-lg-4 col-sm-12">
            <Contactinfo/>
          </div>
        </div>
      </div>
    </div>
  );
}
