import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "../src/locales/en/translation.json"
import ar from "../src/locales/ar/translation.json"
const language = localStorage.getItem('i18nextLng') || 'ar'; // استرجاع اللغة من localStorage أو استخدام 'ar' كقيمة افتراضية

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en }, // قراءة الترجمة من ملف JSON
      ar: { translation: ar }, // قراءة الترجمة من ملف JSON
    },
    lng: language, // استخدام اللغة المسترجعة من localStorage
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React يقوم بالتحصين بشكل افتراضي
    },
  });

export default i18n;
