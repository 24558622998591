import logo from "./logo.svg";
import "./App.css";

import Home from "./components/Home/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from "./components/About/About";
import Layout from "./components/Layout/Layout";
import Faqs from "./components/faqs/Faqs";
import Contact from "./components/contact/Contact";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Profile from "./components/Profile/Profile";
import ForgetPassword from "./components/Auth/ForgetPassord/ForgetPassword";
import Checkcode from "./components/Auth/ForgetPassord/Checkcode";
import LanguageSelector from "./LanguageSelector";

function App() {

  let routes = createBrowserRouter([



    { 
      path: '/', element: <Layout />, children: [
      { index:true, element:<Home/> },
      { path: "About", element: <About /> },
      { path: "Contact", element: <Contact /> },
      { path: "Faqs", element: <Faqs /> },
      { path: "Login", element: <Login /> },
      { path: "Register", element: <Register /> },
      { path: "profile", element: <Profile /> },
      { path: "ForgetPassword", element: <ForgetPassword /> },
      { path: "Checkcode", element: <Checkcode /> },

    ]
  
  
    
  }
  ]);


  return (
    <div className="App">
      {/* <Home /> */}
      {/* <LanguageSelector></LanguageSelector> */}
      <RouterProvider router={routes}></RouterProvider>
    
    </div>
  );
}

export default App;
