import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useQuery } from "react-query";


export default function Contactinfo() {




    const contact_info = async () => {
        const { data } = await axios.post('https://shipments.ahlanuof.com/api/contact-info', {
          company_id: "262"
        });
        return data;
      };
    
      const { data, error, isLoading } = useQuery(['contact_info'], contact_info);
    
      if (isLoading) return <div>Loading...</div>;
      if (error) return <div>Error: {error.message}</div>;
    
      const sliderData = data?.data || [];


// console.log("sliderDa012ta", sliderData);

  return (
    <div>
    

      <div className="container mt-5">
        <div className="row">
    






         <div className="col-lg-12 col-sm-12">
 <div className="w-100">
     <div className="bg-color">
         <h3 className="text-white text-center p-3">معلومات التواصل</h3>
     </div>
     <div className="mt-3">
         <div className="d-flex">
             <div className="mx-4 mt-3">
             <i class="fa-regular fa-clock fa-2x"></i>
             </div>
             <div>
                 <h5 className="color-text ">  {sliderData[0].title}</h5>
                 <p> {sliderData[0].content}</p>
             </div>
         </div>
         <div className="d-flex mt-4">
             <div className="mx-4 mt-3">
             <i class="fa-regular fa-envelope fa-2x"></i>
             </div>
             <div>
                 <h5 className="color-text ">  {sliderData[1].title}  </h5>
                 <p> {sliderData[1].content}</p>
             </div>
         </div>
         <div className="d-flex mt-4">
             <div className="mx-4 mt-3">
             <i class="fa-solid fa-phone fa-2x"></i>
             </div>
             <div>
                 <h5 className="color-text ">   {sliderData[2].title}  </h5>
                 <p>{sliderData[2].content}</p>
             </div>
         </div>
     </div>
 </div>
 </div>


        </div>
      </div>

    </div>
  );
}

