import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

export default function Express() {
const [deta, setdeta] = useState([])
const { t } = useTranslation();
  const features = async () => {
    const { data } = await axios.post('https://shipments.ahlanuof.com/api/home', {
      company_id: '262',
    });
    return data;
  };

  const { data, error, isLoading } = useQuery(['features'], features);

  // setdeta(data?.data)
console.log(data?.data?.features);

  return (
    <div>
      <div className="mt-5">
        <div className=" text-center">
          <h1 className="color-text text-color">       {t("fastshipping")} </h1>
          <h5 className="fw-bold mt-3">
          {t("titlesiction")} 
          </h5>
        </div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-3 col-md-4 col-sm-6 mt-5">
              <div className="hoverCart">
                <div className="pt-3">
                  <div className="icon-cart d-center mx-auto">
                    <i class="fa-solid fa-business-time  fa-lg"></i>
                  </div>
                  <div className="pt-3 text-center">
                    <h4 className=" text-center">   {data?.data?.features[0]?.title}</h4>
                    <div className="pt-3">
                      <p>
                      {data?.data?.features[0]?.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mt-5">
              <div className="hoverCart">
                <div className="pt-3">
                  <div className="icon-cart d-center mx-auto">
                    <i class="fa-solid fa-credit-card"></i>
                  </div>
                  <div className="pt-3 text-center">
                  <h4 className=" text-center">   {data?.data?.features[1]?.title}</h4>
                    <div className="pt-3">
                      <p>
                      {data?.data?.features[1]?.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mt-5">
              <div className="hoverCart">
                <div className="pt-3">
                  <div className="icon-cart d-center mx-auto">
                  <i class="fa-solid fa-shield-halved"></i>
                  </div>
                  <div className="pt-3 text-center">
                  <h4 className=" text-center">   {data?.data?.features[2]?.title}</h4>
                    <div className="pt-3">
                      <p>
                      {data?.data?.features[2]?.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mt-5">
              <div className="hoverCart">
                <div className="pt-3">
                  <div className="icon-cart d-center mx-auto">
                  <i class="fa-solid fa-circle-dollar-to-slot"></i>
                  </div>
                  <div className="pt-3 text-center">
                  <h4 className=" text-center">   {data?.data?.features[3]?.title}</h4>
                    <div className="pt-3">
                      <p>
                      {data?.data?.features[3]?.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
