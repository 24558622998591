import React, { useState } from 'react';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BallTriangle, FallingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('https://shipments.ahlanuof.com/api/clients/login', formData);
      // console.log('Login successful:', response.data);
      if (response.data.status === true) {
        toast.success(response?.data.message);
        localStorage.setItem('token', response.data?.data.token);
        navigate('/');
      } else {
        toast.error(response?.data.message);
      }
      // Handle successful login response here (e.g., save token, redirect, etc.)
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Error logging in, please try again.');
      // Handle login error here (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt- pt-1">
      <div className="text-center col-5 mx-auto login-container mt-3">
        <img
          src="https://webwith.sahla-eg.com/static/media/logo.862c0c531ee504ef9d75.png"
          className="pt-3 img-100 login-img"
          alt="Logo"
        />
        <form onSubmit={handleSubmit}>
          <div className="mt-5 px-3">
            <label htmlFor="email" className="fw-bold text-dark text-start d-flex">
              البريد الاكتروني 
            </label>
            <div className="mb-3 mt-2">
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="  "
              />
            </div>
          </div>
          <div className="mt-3 px-3">
            <label htmlFor="password" className="d-flex text-dark fw-bold">
              كلمة المرور
            </label>
            <div className="input-group position-relative">
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control mt-2 p-2"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <span
                className="position-absolute top-50 start-0 mx-2 translate-middle-y me-3 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
              </span>
            </div>
          </div>
          <button type="submit" className="btn bg-color text-white w-75 mt-3 mx-auto" disabled={isLoading}>
            {isLoading ? (
              <span>
                <i className="fas fa-spinner fa-spin"></i> جاري التحميل...
              </span>
            ) : (
              'تسجيل الدخول'
            )}
          </button>
        </form>
        <div className="text-center mt-4 mb-3  justify-content-between mx-5 pb-4">
          <a href="/Register" className="fw-bold mt-2 text-decoration-none">
            تسجيل حساب جديد
          </a>
          <a href="ForgetPassword" className="fw-bold mt-2 text-decoration-none d-block pb-2">
            هل نسيت كلمة المرور؟
          </a>
        </div>
      </div>
    </div>
  );
}
