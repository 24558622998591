import axios from 'axios';
import React from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';

export default function Faqs() {
  const fetchQuestions = async () => {
    const { data } = await axios.post('https://shipments.ahlanuof.com/api/app-question', {
      company_id: "262"
    });
    return data;
  };

  const fetchHome = async () => {
    const { data } = await axios.post('https://shipments.ahlanuof.com/api/home', {
      company_id: "262"
    });
    return data;
  };
  const { data: homeData, error: homeError, isLoading: homeLoading } = useQuery(['staticimg'], fetchHome);

  const { data, error, isLoading } = useQuery(['question'], fetchQuestions);
// console.log(homeData?.data.staticImg[0].image);
  if (isLoading) return <div>
     <div className='d-center'>
    <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#f9bf3c"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
    </div>
  </div>;
  if (error) return <div>Error: {error.message}</div>;

  const questionsData = data?.data || [];

  return (
    <div>
      <div className="image-container">
        <img 
          src={homeData?.data.staticImg[0].image}
          alt="About" 
          className="background-image"
        />
        <div className="text-overlay"> الاستفسارات</div>
      </div>
      <div className='mt-5 mb-5 container'>
        <h3 className='color-text'>حصرنا لكم الأسئلة المتكررة والأكثر شيوعاً عن خدمتنا</h3>
        <div className="mt-4">
          <div className="accordion" id="accordionExample">
            {questionsData.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`heading${index}`}>
                  <button 
                    className="accordion-button collapsed bg-step color-text fw-bold" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={`#collapse${index}`} 
                    aria-expanded="false" 
                    aria-controls={`collapse${index}`}
                  >
                    {item.question}
                  </button>
                </h2>
                <div 
                  id={`collapse${index}`} 
                  className="accordion-collapse collapse" 
                  aria-labelledby={`heading${index}`} 
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="fw-bold text-dark">{item.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
