import React, { useState, useEffect } from 'react';

export default function ScrollToTop() {
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the delay as necessary
  }, []);

  return (
    <div>
   

      {!loading && (
        <>
          {showButton && (
            <button onClick={scrollToTop} className="scroll-to-top">
              <i className="fa fa-arrow-up"></i>
            </button>
          )}
          {/* WhatsApp icon */}
          <div className="container">
            <a href="https://wa.me/your_phone_number" target="_blank" rel="noopener noreferrer" className="scroll-fexid-bottom">
              <i className="fa fa-comment" aria-hidden="true"></i>
            </a>
          </div>
        </>
      )}
    </div>
  );
}
