import React from 'react'

export default function Steps() {
  return (
    <div>
      <div className="bg-step mt-4  bg-section">
        <p className="text-center text-white pt-5">
        خطوات سهلة للحصول على الخدمة ....
        </p>



        <div class="container ">
    <div class="progress-indicator   row mb-5">
      <div class="step col-lg-3 col-md-6 col-sm-12 mt-2 mb-5 pb-5">
        <div class="step-circle">
          <i class="fa fa-arrow-left"></i>
          <span className='fw-bold font-20'>1</span>
        </div>
        <div class="step-title">انشاء حساب</div>
      </div>
      <div class="step col-lg-3 col-md-6 col-sm-12 mt-2 mb-5 pb-5">
        <div class="step-circle">
          <i class="fa fa-truck"></i>
          <span>2</span>
        </div>
        <div class="step-title">ابدأ شحنتك</div>
      </div>
      <div class="step col-lg-3 col-md-6 col-sm-12 mt-2 mb-5 pb-5">
        <div class="step-circle">
          <i class="fa fa-credit-card fa-3x"></i>
          <span>3</span>
        </div>
        <div class="step-title">اتمام الدفع</div>
      </div>
      <div class="step col-lg-3 col-md-6 col-sm-12 mt-2 mb-5 pb-5">
        <div class="step-circle">
          <i class="fa fa-handshake  fa-3x"></i>
          <span>4</span>
        </div>
        <div class="step-title">استلم شحنتك</div>
      </div>
    </div>
  </div>

      </div>
    </div>
  )
}
