import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from "../../../src/logo.png";
import axios from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../LanguageSelector';
// import { useTranslation } from '../../TranslationContext';

export default function Navbar() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // دالة لتسجيل الخروج
  const logout = () => {
    localStorage.clear("token");
    navigate('/login'); // إعادة توجيه المستخدم إلى صفحة تسجيل الدخول
  };

  // Handle scroll
  React.useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector('.fixed-top-nav');
      if (window.scrollY > 100) {
        nav.classList.add('scrolled');
      } else {
        nav.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Fetch social media
  const fetchSocialMedia = async () => {
    const { data } = await axios.post('https://shipments.ahlanuof.com/api/home', {
      company_id: '262',
    });
    return data;
  };

  const { data, error: socialMediaError, isLoading } = useQuery(['socialMedia'], fetchSocialMedia);

  // Fetch user data
  const fetchData = async () => {
    try {
      const response = await fetch(`https://shipments.ahlanuof.com/api/clients/user`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,      
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 5000); // 5000 milliseconds = 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className='bg-top-header'>
        <div className="container">
          <div className="row p-2">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="d-flex justify-content-start">
                <p className='fw-bold text-white pt-1 px-3'>
                  {t('contact_via_whatsapp')}: {isLoading ? t('loading') : data?.data?.socialMedia[0]?.phone}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="d-center d-responsev">
                <p className='fw-bold text-white pt-1 px-3'><i className="fa fa-envelope" aria-hidden="true"></i></p>
                <p className='text-white pt-1'> {isLoading ? t('loading') : data?.data?.socialMedia[0]?.email} </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="d-flex justify-content-end d-responsev">
                <div className='fw-bold text-white pt-1'> {isLoading ? t('loading') : data?.data?.socialMedia[0]?.description} </div>
              </div>
            </div>
            <div className='col-1 d-flex justify-content-end'>
              <LanguageSelector></LanguageSelector>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-nav fixed-top-nav">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src={logo} className='img-header' alt="" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                <li className={`nav-item ${location.pathname === '/' ? 'bg-itemNav text-white' : ''}`}>
                  <a className="nav-link active fw-bold" aria-current="page" href="/">{t('home')}</a>
                </li>
                <li className={`nav-item ${location.pathname === '/About' ? 'bg-itemNav' : ''}`}>
                  <a className="nav-link fw-bold" href="About">{t('about_us')}</a>
                </li>
                <li className={`nav-item ${location.pathname === '/Contact' ? 'bg-itemNav' : ''}`}>
                  <a className="nav-link fw-bold" href="Contact">{t('contact_us')}</a>
                </li>
                <li className={`nav-item ${location.pathname === '/Faqs' ? 'bg-itemNav' : ''}`}>
                  <a className="nav-link fw-bold" href="Faqs">{t('faqs')}</a>
                </li>
              </ul>
              <form className="d-flex">
                {localStorage.getItem("token") ? (
                  <div className="dropdown">
                    <button className="btn bg-color text-white fw-bold dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      {t('hello')} {userData?.data?.client?.name}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>
                        <button className="dropdown-item" onClick={() => navigate('/profile')}>
                          {t('profile')}
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" onClick={logout}>
                          {t('logout')}
                        </button>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <a className='login-button btn rounded-pill text-btn px-4' href='/login'>
                    <span className='mt-1 px-2'>
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </span>
                    <button className="btn fw-bold text-btn p-0 m-0" type="button">{t('login')}</button>
                  </a>
                )}
              </form>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
