import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function Profile() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    company_id:"262"
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  let navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put('https://shipments.ahlanuof.com/api/clients/user', formData, {
        headers: {
          'Authorization': `Bearer ${token}`, // تضمين الـ token في الرؤوس
        },
      });

      if (response.data?.status === true) {
        toast.success(response?.data.message);
        // console.log(response?.data);
        // localStorage.setItem('token', response?.data?.token);
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      console.error('Error registering:', error);
      toast.error('Error registering, please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-5">
      <div className="text-center col-5 mx-auto login-container ">
      <h5 className='fw-bold'>تعديل الملف الشخصي</h5>
        {/* <img
          src="https://webwith.sahla-eg.com/static/media/logo.862c0c531ee504ef9d75.png"
          className="pt-3 img-100 login-img"
          alt="Logo"
        /> */}
        <form onSubmit={handleSubmit}>
          <div className="mt-3 px-3">
            <label htmlFor="name" className='d-flex text-dark fw-bold'> الاسم</label>
            <input
              type="text"
              className='form-control mt-2'
              name="name"
              id="name"
              placeholder='كتابة الاسم'
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mt-3 px-3">
            <label htmlFor="email" className='d-flex text-dark fw-bold'> البريد الالكتروني</label>
            <input
              type="email"
              className='form-control mt-2'
              name="email"
              id="email"
              placeholder='البريد الاكتروني'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mt-3 px-3">
            <label htmlFor="phone" className='fw-bold text-dark text-start d-flex'> رقم الهاتف</label>
            <div className="input-group mb-3 mt-2">
              <input
                type="text"
                className="form-control p-2"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder=" رقم الهاتف "
                required
              />
              {/* <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">+968</span>
              </div> */}
            </div>
          </div>
          <div className="mt-3 px-3">
            <label htmlFor="password" className='d-flex text-dark fw-bold'>كلمة المرور</label>
            <input
              type="password"
              className='form-control mt-2'
              name="password"
              placeholder='كلمة المرور'
              id="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button className='btn bg-color text-white w-75 mb-4 mt-3 px-3' type="submit" disabled={isLoading}>
            {isLoading ? 'جارٍ التسجيل...' : 'تحديث الملف الشخصي'}
          </button>
        </form>
        {/* <div className="text-center mt-4 mb-3">
          <a href="/Register" className='fw-bold mt-2 text-decoration-none'>
            تسجيل حساب جديد
          </a>
          <a href="#" className='fw-bold mt-2 text-decoration-none d-block pb-2'>
            هل نسيت كلمة المرور؟
          </a>
        </div> */}
      </div>
    </div>
  );
}
