import React from 'react'

export default function Footer() {
  return (
    <footer className='bg-step mt-5'>
      <div className="text-center py-4">
        <p>سياسة الخصوصية - الشروط و الاحكام</p>
        <p>جميع الحقوق محفوظة © 2024 <span className='fw-bold color-text'>معك للشحن السريع</span></p>
      </div>
    </footer>
  )
}
